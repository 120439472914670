import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import { ConditionalWrapper } from "src/components/conditional-wrapper"
import { Image } from "src/components/image"
import { use100vh } from "react-div-100vh"

import styles from "./hero.module.css"

const HeroMedia = ({ hero }) => {
  const { figure } = hero

  return (
    <>
      {figure && figure.asset && (
        <Image
          className={styles.heroImage}
          imageId={figure.asset._id}
          width={2400}
          style={{ height: "100%" }}
          objectFit="cover"
          objectPosition="50% 25%"
          loading="eager"
          alt={figure.alt ? figure.alt : "Home Video"}
        />
      )}
    </>
  )
}

const FeaturedRelease = ({ release }) => {
  const { artwork, title, tracklist } = release
  return (
    <div className={styles.featuredRelease}>
      {artwork && artwork.asset && (
        <Image
          className={styles.artwork}
          imageId={artwork.asset._id}
          width={960}
          alt={title}
        />
      )}
      <h2 className={cx("headline", styles.title)}>{title}</h2>
    </div>
  )
}

export const Hero = ({ hero }) => {
  const vh = use100vh()

  return (
    <>
      <Link to="/discography">
        <div
          className={styles.hero}
          style={{
            "--vh": `${vh}px`,
          }}
        >
          <HeroMedia hero={hero} />

          {/* {hero.featuredRelease && (
        <FeaturedRelease release={hero.featuredRelease} />
      )} */}
          {/* <h1 className={cx("headline", styles.headline)}>{hero.headline}</h1> */}
        </div>
      </Link>
    </>
  )
}
