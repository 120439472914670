import React from "react"
import { RevealOnMount } from "components/reveal-on-mount"
import SEO from "components/seo"
import { Hero } from "components/hero"

const LandingPage = ({ location, pageContext }) => {
  return (
    <RevealOnMount location={location}>
      <SEO title="Home Video" />
      <Hero hero={pageContext.hero} />
    </RevealOnMount>
  )
}

export default LandingPage
