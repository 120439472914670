import React from "react"
import { graphql } from "gatsby"
import LandingPage from "src/templates/landing-page"
import Image from "../components/image"
import SEO from "../components/seo"

export const query = graphql`
  query HomepageQuery {
    page: sanityLandingPage(_id: { regex: "/(drafts.|)homepage/" }) {
      title
      hero: _rawHero(resolveReferences: { maxDepth: 10 })
    }
  }
`

const IndexPage = ({ data, location }) => {
  const pageContext = data.page
  return <LandingPage location={location} pageContext={pageContext} />
}

export default IndexPage
